@import url("https://fonts.googleapis.com/css?family=Muli:400,700&display=swap&subset=vietnamese");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Muli", sans-serif;
}

.zid-container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  margin-top: 55px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 0 15px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #22a0ea;
  position: fixed;
  top: 0;
  z-index: 100;
}
.homePage {
  margin-top: 55px;
}
.info-acc {
  color: #454545;
  font-size: 12px;
}
.info-acc b {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
  color: #22a0ea;
}
/*.info-acc a {
  color: #db1f1f;
}*/
.header img {
  height: 40px;
  /*max-width: 100%;*/
}

.banner {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
  height: 250px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid #eee;
}
.banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(20%, hsla(0, 0%, 100%, 0.26)),
    to(rgba(106, 4, 4, 0.26))
  );
  background: linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0.26) 20%,
    rgba(106, 4, 4, 0.26)
  );
}
.banner img {
  width: 100%;
}

.list-game {
  margin-bottom: 100px;
}
.list-item {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
}
.list-item .thumbnail {
  width: 100%;
  height: 170px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  background-color: #f5f5f5;
}
.list-item .thumbnail img {
  width: 100%;
  border-radius: 5px;
  display: block;
  box-shadow: #ccc 0 0 5px;
  transition: all 0.3s;
}
.list-item h3 {
  font-weight: 700;
  color: #454545;
  padding: 0;
  font-size: 15px;
  margin-bottom: 10px;
  transition: all 0.3s;
}
.list-item a {
  display: inline-block;
  border-radius: 30px;
  font-weight: 400;
  font-size: 12px;
  padding: 5px 14px;
  background: #22a0ea;
  color: #fff;
  text-decoration: none;
  position: absolute;
  right: 10px;
  bottom: 10px;
  transition: all 0.3s;
}
.list-item a:hover {
  background: #0069a7;
  text-decoration: none;
}
.list-item:hover h3 {
  color: #22a0ea;
}
.list-item:hover .thumbnail img {
  box-shadow: #22a0ea 0 0 5px;
  opacity: 0.8;
}

.searchGame {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  z-index: 11;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.searchGame h1 {
  color: #fff;
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: #242424 0 0 4px;
}
.searchGame h1::after {
  content: "";
  display: block;
  width: 70px;
  height: 1px;
  background: #fff;
  margin: 10px auto;
  box-shadow: #242424 0 0 3px;
}
.form-search input {
  height: 70px;
  line-height: 70px;
  font-size: 18px;
  padding: 0 15px;
  padding-left: 70px;
  border-radius: 10px;
  font-weight: 300;
  box-shadow: 0 0 3px 0 rgba(145, 121, 121, 0.32);
  width: 100%;
  border: 1px solid #eee;
  outline: none;
  transition: all 0.3s;
}
.form-search input:focus {
  box-shadow: 0 0 3px 0 #22a0ea;
  border-color: #22a0ea;
}
.form-search input::-webkit-input-placeholder {
  font-size: 18px;
}
.form-search input:-ms-input-placeholder {
  font-size: 18px;
}
.form-search input::placeholder {
  font-size: 18px;
}
.form-search {
  position: relative;
}
.form-search .MuiSvgIcon-root {
  position: absolute;
  font-size: 40px;
  left: 17px;
  top: 15px;
}

.filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.filter h3 {
  font-size: 24px;
  color: #454545;
  margin-bottom: 20px;
  font-weight: 700;
}
.btnGroup button {
  padding: 7px 15px;
  font-size: 12px;
  border: 1px solid #dcdfe6;
  background: #fff;
  outline: none;
  border-radius: 30px;
  margin-left: 10px;
  color: #606266;
  transition: all 0.3s;
}
.btnGroup button:hover,
.btnGroup button.active {
  background: #22a0ea;
  color: #fff;
  border-color: #22a0ea;
}

.login {
  width: 100vw;
  min-height: 100vh;
  background: #b8e5ff;
  position: relative;
}
.login-wrapper {
  width: 100%;
  max-width: 960px;
  height: fit-content;
  min-height: 450px;
  box-shadow: #a6a6a6 0 0 5px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  border-radius: 5px;
  overflow: hidden;
}
.block-login {
  width: 60%;
  background: rgb(255 255 255 / 0.8);
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding-top: 120px;
  justify-content: center;
}
/* .block-login::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 17%;
  margin: 0 auto;
  width: 1px;
  height: 50%;
  background: #dcdfe6;
} */
.block-login b {
  display: block;
  font-size: 14px;
  margin-bottom: 20px;
}
.logo-game {
  width: 100%;
  border-bottom: 1px solid #eee;
  height: 120px;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  padding-top: 10px;
}
.logo-game img {
  height: 100px;
}
.login-form {
  width: 50%;
  padding: 30px;
  position: relative;
  max-width: 50%;
}
.login-form::after {
  content: "";
  position: absolute;
  right: 0;
  margin: 0 auto;
  width: 1px;
  height: 225px;
  background: #dcdfe6;
  z-index: 99;
  top: 9%;
}
/* .login-form .MuiFormControl-root {
  width: 100%;
  margin-bottom: 10px;
} */
.login-form input {
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #22a0ea;
  outline: none;
  margin-bottom: 20px;
  padding: 0 10px;
}
.login-form button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}
.login-method {
  width: 50%;
  padding: 30px;
  max-width: 50%;
}
/* .login-method button {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  outline: none;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 10px;
}
.login-method button i {
  margin-right: 10px;
  font-size: 20px;
} */
.login-method button {
  width: 100%;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: bold;
}
.login-method .MuiButton-label {
  justify-content: flex-start;
  align-items: center;
}
.col-container {
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 100px);
}
.side-menu {
  width: 290px;
  box-shadow: 0 1px 4px -1px #c8c8c8;
  align-self: flex-start;
}
.menu-item {
  display: block;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}
.side-menu .menu-item:last-child {
  border: 0;
}
.menu-item a {
  display: block;
  width: 100%;
  min-height: 80px;
  background: #f8f8f8;
  border-left: 3px solid #f8f8f8;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding-left: 50px;
  font-size: 12px;
  text-decoration: none;
  color: #454545;
  transition: all 0.3s;
}
.menu-item.active a,
.menu-item:hover a {
  border-color: #2eba7d;
  background: #ebebeb;
}
.menu-item a b {
  display: block;
  width: 100%;
  font-size: 14px;
}
.menu-item a span {
  display: block;
  width: 100%;
}
.menu-item a .MuiSvgIcon-root {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  margin: auto 0;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 30px;
  font-size: 14px;
}
.menu-item a img {
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  text-align: center;
  margin: auto 0;
}
.block-content {
  width: calc(100% - 290px);
  border-left: 1px solid #eee;
  padding: 20px;
}
.footer {
  font-size: 12px;
  color: #afafaf;
  border-top: 1px solid #eee;
  height: 45px;
  line-height: 45px;
  text-align: center;
  /*position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;*/
}
.heading {
  font-size: 20px;
  border-bottom: 3px double #e5e5e5;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}
.customLink {
  color: white !important;
  font-weight: 700 !important;
}
.userInfo {
  width: 100%;
}
.userInfo .infoItem {
  display: block;
  width: 100%;
  padding: 13px 0;
  font-size: 14px;
  min-height: 18px;
  border-bottom: 1px solid #eee;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.userInfo .infoItem:last-child {
  border-bottom: 0;
}
.userInfo .infoItem .label {
  width: 150px;
  color: #22a0ea;
  font-weight: bold;
}
.userInfo .infoItem .text {
  flex: 1;
  color: #454545;
}
.userInfo .infoItem .text .MuiTextField-root {
  width: 100%;
}
.userInfo .subInfo {
  width: 100%;
  border-left: 3px solid #eee;
  padding-left: 15px;
}
.userInfo .subInfo p b {
  display: inline-block;
  width: 100px;
  color: #22a0ea;
}
.userInfo .infoItem .action {
  width: 75px;
}
.userInfo .infoItem .action button {
  font-size: 11px;
  color: #fff;
  text-transform: capitalize;
  padding: 2px 10px;
}
.userInfo .infoItem .action button + button {
  margin-top: 10px;
}
.userInfo .verfify-form fieldset {
  border-color: #e0e0e0;
}
/* switch account */
.transfer-result {
  max-width: 630px;
  margin: 100px auto;
  text-align: center;
}
.transfer-result p {
  font-size: 14px;
}
.transfer-result .success {
  color: #219653;
  font-weight: 700;
  font-size: 18px;
}
.account-switch {
  margin-bottom: 20px;
}
.account-switch .heading {
  font-size: 30px;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.account-switch .MuiSelect-root,
.account-switch .MuiInputBase-root {
  max-height: 32px;
  width: 235px;
  overflow: hidden;
  /*margin: 0 15px 0 30px;*/
}
.account-switch button {
  max-height: 32px;
  text-transform: capitalize;
}

.account-switch .account-switch__row {
  display: flex;
  /*align-items: center;*/
  gap: 15px;
  padding: 16px 0;
}
.account-switch__row + .account-switch__row,
.account-switch__col + .account-switch__row {
  border-top: 1px solid #e0e0e0;
}

.account-switch__row.border-bot {
  border-bottom: 1px solid #e0e0e0;
}
.account-switch__row .label {
  font-size: 12px;
  color: #0a8cd9;
  width: 140px;
  font-weight: 700;
  word-break: break-word;
}

.account-switch__row .label.required::after {
  content: " *";
  color: #eb5757;
}
.account-switch .account-switch__note {
  font-size: 12px;
  line-height: 15px;
  margin: 24px 0 12px;
}
.account-switch .MuiButtonBase-root:disabled {
  background-color: #bdbdbd !important;
  cursor: not-allowed;
}
/*.account-switch .account-switch__resend-otp {
  margin-bottom: 40px;
}*/
.account-switch .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
}
.account-switch .transfer-err {
  color: #eb5757;
  display: flex;
  gap: 5px;
  margin-top: 14px;
  align-items: center;
}
.account-switch .account-switch__resend-otp .countdown {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 8px;
}
.account-switch .suggest-link {
  margin-left: 150px;
  margin-bottom: 16px;
  max-width: 293px;
}
.account-switch .suggest-link button {
  margin-top: unset !important;
}
.account-switch .suggest-link button + button {
  margin-left: 5px;
}
.account-switch .MuiButtonBase-root {
  height: fit-content;
}
.account-switch .row-wrap {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.account-switch .Mui-error.MuiFormHelperText-filled {
  margin-right: unset;
}
.account-switch .MuiLink-root {
  color: #2d9cdb;
  margin-top: 8px;
}
.account-switch .MuiLink-root.disable {
  color: #bdbdbd;
}
.account-switch .ident-otp {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 3px double #e0e0e0;
}
.history-switch-account {
  margin-top: 24px;
  margin-bottom: 12px;
  overflow-y: auto;
  max-height: 200px;
}
.history-link {
  display: flex;
  align-items: center;
}
.history-switch-account .history-switch-account__row {
  padding-right: 15px;
}
.history-switch-account .history-switch-account__row,
.history-switch-account__row .right,
.history-switch-account__row .left {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.history-switch-account__row .label {
  color: #828282;
  margin-bottom: 24px;
}

.history-switch-account__row .left {
  width: 60%;
}
.history-switch-account__row .right {
  width: 40%;
}
.history-switch-account__row {
  text-align: center;
}
.history-switch-account__row:not(:first-child) .label {
  display: none;
}
.history-switch-account__row p {
  margin: unset;
  font-size: 12px;
  line-height: 15px;
  /*font-weight: 600;*/
}
.history-switch-account__row p.success {
  color: #219653;
  font-weight: 700;
}
.history-switch-account__row p.fail {
  color: #eb5757;
  font-weight: 700;
}
.history-switch-account__row + .history-switch-account__row {
  margin-top: 32px;
}
.history-switch-account .game-accn {
  width: 160px;
  word-break: break-all;
  text-align: left;
}
.history-switch-account .zing-accn {
  width: 100px;
  word-break: break-all;
  text-align: left;
}
.MuiMenu-paper .transfer-link-menu:hover {
  color: inherit;
}
.MuiMenu-paper .transfer-link-menu {
  display: none;
}
.transfer-link {
  color: #4f4f4f;
  font-size: 14px;
}
/*.guide-link-desktop {
  font-size: 12px;
}*/
.user-menu .MuiMenu-list .MuiListItem-button + .MuiListItem-button {
  border-top: 1px solid #e0e0e0;
}
.user-menu .MuiPopover-paper {
  border-radius: 8px;
}
.user-menu .MuiPopover-paper .MuiMenu-list {
  padding: 8px 16px;
}
.has-transfer .heading {
  padding-top: 20px;
  border-bottom: unset;
  border-top: 3px double #e0e0e0;
}

/* end switch accout */
.warning-notification {
  background: #fffd63;
  border-left: 5px solid #fea220;
  padding: 10px;
  font-size: 14px;
  color: #454545;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.warning-notification .MuiSvgIcon-root {
  font-size: 50px;
  margin-right: 15px;
  color: #fea220;
}
.warning-notification p {
  margin: 0;
}
.warning-info {
  background: #faeacb;
  border-left: 5px solid #fca235;
  padding: 10px;
  font-size: 12px;
  color: #454545;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin: 20px 0 40px;
}
.warning-info .right {
  margin-left: 12px;
}
.warning-info .right p {
  margin-bottom: 12px;
}
.update-info {
  min-height: calc(100vh - 100px);
  padding-bottom: 50px;
  margin-top: 100px;
}
.col-center {
  width: 100%;
  max-width: 500px;
  padding: 0 15px;
  margin: 0 auto;
}
.update-info .MuiFormControl-root {
  width: 100%;
  margin-bottom: 20px;
}
.update-form {
  padding: 30px;
  background: #f8f8f8;
}
.update-form .MuiFormControl-root {
  width: 100%;
  margin-bottom: 20px;
}
.MuiFormGroup-root.rowClass {
  flex-direction: row;
}
h3.heading-upd {
  font-size: 24px;
  font-weight: bold;
  border-bottom: 1px solid #e5e5e5;
  border-left: 3px solid #2eba7d;
  padding: 5px 10px;
  border-radius: 5px 0 0 5px;
  background: #edfff7;
  margin-bottom: 30px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.btn-update {
  background: rgb(34, 169, 239);
  width: 100%;
  padding: 15px 20px;
  margin-top: 20px;
}
.button-align {
  width: 100%;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #eee;
}
.button-align.right {
  text-align: right;
}
.button-align.left {
  text-align: left;
}
.button-align.center {
  text-align: center;
}
.button-align button + button {
  margin-left: 15px;
}
.list-group {
  max-height: 230px;
  overflow: auto;
}
.alert-popup-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  margin: 0 auto;
  width: 90%;
  max-width: 500px;
  z-index: 9999;
}
.alert-popup {
  width: 100%;
  background: #fdecea;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: #f55448 0 0 5px;
  margin-bottom: 0;
  height: 0;
  overflow: hidden;
  animation: alertIn 4s 1;
  animation-fill-mode: forwards;
}
.alert-popup .icon {
  color: #f55448;
  margin-right: 5px;
}

@keyframes alertIn {
  0% {
    height: 50px;
    margin-bottom: 10px;
    display: block;
    opacity: 0;
    visibility: hidden;
    padding: 10px 15px;
  }
  10% {
    opacity: 1;
    visibility: visible;
  }
  90% {
    height: 50px;
    margin-bottom: 10px;
    opacity: 0;
    visibility: hidden;
    padding: 10px 15px;
  }
  100% {
    height: 0;
    margin-bottom: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .col-container {
    flex-direction: column;
  }
  .side-menu {
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 100;
  }
  .menu-item {
    flex: 1;
    border: 0;
    border-right: 1px solid #ebebeb;
  }
  .menu-item a {
    border-left: 0;
    border-bottom: 3px solid transparent;
  }
  .block-content {
    width: 100%;
    margin-top: 120px;
  }
  .filter,
  .list-game {
    padding: 0 15px;
  }
  .login-wrapper {
    max-width: 700px;
  }
  .block-login {
    width: 100%;
  }
  .history-switch-account .history-switch-account__row {
    flex-direction: column;
  }
  .history-switch-account__row:not(:first-child) .label {
    display: block;
  }
  .history-switch-account__row .right,
  .history-switch-account__row .left {
    width: 100%;
  }
  .history-switch-account__row .right .info:first-child,
  .history-switch-account__row .left .info:first-child {
    text-align: left;
  }
  .history-switch-account__row .right .info:last-child,
  .history-switch-account__row .left .info:last-child {
    text-align: right;
  }
  .history-switch-account__row + .history-switch-account__row {
    border-top: 1px solid #e0e0e0;
    margin-top: unset;
  }
  .history-switch-account__row .label {
    margin-bottom: 4px;
    margin-top: 16px;
  }
  .history-switch-account__row {
    margin-bottom: 16px;
  }
  .history-switch-account .history-switch-account__row,
  .history-switch-account__row .right,
  .history-switch-account__row .left {
    gap: 0;
  }
  .history-switch-account .zing-accn,
  .history-switch-account .game-accn {
    width: auto;
  }
  .history-switch-account .game-accn {
    text-align: right;
  }
  .history-switch-account .left .info:first-child {
    width: 40%;
  }
  .history-switch-account .left .info:last-child {
    width: 55%;
  }
  .transfer-result {
    padding: 0 20px;
  }
  .MuiMenu-paper .transfer-link-menu {
    display: flex;
    align-items: center;
  }
  .guide-link-desktop {
    display: none;
  }
}

@media (max-width: 480px) {
  .menu-item a {
    text-align: center;
    padding: 5px;
    height: 100%;
  }
  .menu-item a .MuiSvgIcon-root,
  .menu-item a img {
    position: static;
    margin: auto;
  }
  .userInfo .infoItem .label {
    width: 100px;
    font-size: 12px;
  }
  .userInfo .infoItem.verfify-form .label {
    width: unset !important;
    margin-bottom: 5px;
  }
  .userInfo .infoItem .text {
    width: calc(100% - 180px);
    padding-left: 5px;
  }
  .userInfo .infoItem.verfify-form .text {
    width: 100% !important;
    padding-left: 0 !important;
  }
  .userInfo .infoItem.verfify-form .text > * {
    width: 100% !important;
  }
  .block-content {
    padding: 20px 15px;
  }
  .update-form {
    padding: 20px;
  }
  h3.heading-upd {
    font-size: 20px;
  }
  .userInfo .subInfo {
    margin-bottom: 5px;
  }
  .userInfo .subInfo p b {
    width: 100%;
  }
  .banner {
    height: 150px;
    margin-bottom: 70px;
  }
  .searchGame {
    max-width: 320px;
    padding: 0 15px;
    bottom: -25px;
  }
  .searchGame h1 {
    font-size: 22px;
  }
  .form-search input {
    height: 50px;
    line-height: 50px;
    padding-left: 50px;
  }
  .form-search .MuiSvgIcon-root {
    font-size: 30px;
    left: 15px;
    top: 10px;
  }
  .filter {
    display: block;
    max-width: 320px;
    margin: auto;
    margin-bottom: 40px;
  }
  .btnGroup button {
    margin-left: 0;
    margin-right: 5px;
    padding: 5px 10px;
    margin-bottom: 5px;
  }
  .list-game {
    max-width: 320px;
    margin: auto;
  }
  .login {
    padding: 30px 0;
  }
  .login-wrapper {
    height: auto;
    max-width: 320px;
    margin: 0 auto;
    position: static;
    flex-direction: column;
  }
  .block-login {
    text-align: center;
  }
  .block-login b {
    font-size: 18px;
  }
  .login-form,
  .login-method {
    width: 100%;
    max-width: 100%;
  }
  .login-form::after {
    display: none;
  }
  .col-center {
    max-width: 320px;
  }
  .account-switch {
    margin-top: 20px;
  }

  .account-switch .account-switch__row {
    flex-direction: column;
    gap: 8px;
  }
  .account-switch .MuiSelect-root,
  .account-switch .MuiInputBase-root {
    width: 100%;
    /*margin: 0 15px 0 30px;*/
  }
  .account-switch .row-wrap div:first-child {
    flex: 1;
  }
  .account-switch__row + .account-switch__row,
  .account-switch__col + .account-switch__row {
    border-top: unset;
  }
  .account-switch .account-switch__row {
    padding: 12px 0;
  }
  .account-switch .suggest-link {
    margin-left: 14px;
    max-width: 100%;
  }

  .account-switch .account-switch__resend-otp {
    margin-bottom: 0;
  }
  .account-switch .account-switch__note {
    margin: 12px 0 12px;
  }
  .account-switch .confirm {
    /*border-bottom: 1px solid #e0e0e0;*/
    padding-bottom: 20px;
  }
  .account-switch__row.border-bot {
    border-bottom: unset;
  }
  .header img {
    height: 30px;
    /*max-width: 100%;*/
  }
  .info-acc .header-name-clip {
    max-width: 90px !important;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
